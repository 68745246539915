exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-devel-js": () => import("./../../../src/pages/devel.js" /* webpackChunkName: "component---src-pages-devel-js" */),
  "component---src-pages-drinks-js": () => import("./../../../src/pages/drinks.js" /* webpackChunkName: "component---src-pages-drinks-js" */),
  "component---src-pages-favorites-js": () => import("./../../../src/pages/favorites.js" /* webpackChunkName: "component---src-pages-favorites-js" */),
  "component---src-pages-help-me-decide-js": () => import("./../../../src/pages/help-me-decide.js" /* webpackChunkName: "component---src-pages-help-me-decide-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ingredients-js": () => import("./../../../src/pages/ingredients.js" /* webpackChunkName: "component---src-pages-ingredients-js" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-pages-tags-ten-bottle-bar-js": () => import("./../../../src/pages/tags/ten-bottle-bar.js" /* webpackChunkName: "component---src-pages-tags-ten-bottle-bar-js" */),
  "component---src-pages-techniques-js": () => import("./../../../src/pages/techniques.js" /* webpackChunkName: "component---src-pages-techniques-js" */),
  "component---src-templates-article-template-js": () => import("./../../../src/templates/ArticleTemplate.js" /* webpackChunkName: "component---src-templates-article-template-js" */),
  "component---src-templates-drink-template-js": () => import("./../../../src/templates/DrinkTemplate.js" /* webpackChunkName: "component---src-templates-drink-template-js" */),
  "component---src-templates-tag-template-js": () => import("./../../../src/templates/TagTemplate.js" /* webpackChunkName: "component---src-templates-tag-template-js" */)
}

